<template>
  <v-form ref="validateForm" v-if="showForm">
    <v-row class="formRender">
      <!-- <slot name="combo"></slot> -->
      <template v-for="(item, index) in listOfFields">
        <template v-if="isRecordCreation ? (item.name !== 'routingstatus' ? true : false) : (item.name !== 'isanonymous' && item.name !== 'routingstatus')">
          <template v-if="(item.accesscontrol && item.accesscontrol.edit) && (isRecordCreation && item.type === 18 ? false : true) && !item.isPanelField">
            <v-col class="py-2" v-if="item.conditionscript ? item.isShowElement ? item.isShowElement(fields, self) : () => {} : item.isShow"
              :cols="$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.xsOnly ? '12' :'6' : item.columns ? item.columns : '12'" :md="item.columns ? item.columns : (item.type === 10 || item.type === 11 || item.type === 12 || item.type === 14) ? '12' : '6'"
              :key="item._id">
              <template v-if="item.type === 1">
                <v-text-field outlined :label="$t(item.label)" v-model="fields[item.name]" :placeholder="item.placeholder" dense
                  :id="`_${item.name}`" :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                  :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                  @change="item.name === 'name' ? checkForDuplicateNames(fields[item.name], moduleName, item, index) : checkForValidationOrFunction(item, fields[item.name], index)">
                </v-text-field> <!-- :disabled="item.name === 'routingstatus'" -->
              </template>
              <template v-if="item.type === 2">
                <v-textarea outlined  :label="$t(item.label)" v-model="fields[item.name]" :placeholder="item.placeholder" dense
                  :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                  :id="`_${item.name}`" :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                  @change="checkForValidationOrFunction(item, fields[item.name], index)">
                </v-textarea>
              </template>
              <template v-if="item.type === 3">
                <v-row v-if="item.default_value && item.default_value.is_multiselect">
                  <v-col class="pa-0 pl-2" cols="12">
                    <label>{{ $t(item.label) }}:</label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pa-0 pl-2" cols="12" lg="12" v-if="item.default_value && !item.default_value.is_multiselect">
                    <v-checkbox  :label="$t(item.label)" class="pt-0" v-model="fields[item.name]"
                      :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                      @change="checkForValidationOrFunction(item, fields[item.name], index)">
                    </v-checkbox>
                  </v-col>
                  <v-col class="pa-0 pl-2" cols="12" lg="12" v-else>
                    <template>
                      <v-checkbox  v-model="fields[item.name]" :label="options.label" :value="options.value" :key="optionkey" v-for="(options, optionkey) in item.default_value.options"
                        :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                        @change="checkForValidationOrFunction(item, fields[item.name], index)">
                      </v-checkbox>
                    </template>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col class="pa-0 pl-2" cols="12">
                    <label>{{ item.label }}:</label>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pa-0 pl-2" cols="6" sm="4" md="3" lg="2" v-for="(options, index) in item.default_value.options" :key="index">
                    <v-checkbox  v-model="fields[item.name]" :rules="getValidation(item.validation, 'multiple') || []" :label="options.label" :value="options.value" multiple></v-checkbox>
                  </v-col>
                </v-row> -->
              </template>
              <template v-if="item.type === 4">
                <div>
                  <label class="font-weight-medium grey--text text--darken-2"> {{ $t(item.label) }}: </label>
                  <v-radio-group row v-model="fields[item.name]"
                    :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []" :id="`_${item.name}`"
                    @change="checkForValidationOrFunction(item, fields[item.name], index)" class="ma-0" hide-details
                  >
                    <v-radio v-for="(options, index) in item.default_value.options" :key="index" :label="options.label" :value="options.value"></v-radio>
                  </v-radio-group>
                </div>
              </template>
              <template v-if="item.type === 5">
                <template v-if="item.default_value.selectType === 'api'">
                  <v-autocomplete outlined :items="item.default_value.options" :label="$t(item.label)"  v-model="fields[item.name]" :key="autoCompleteRender"
                    dense :multiple="item.default_value.is_multiselect" :item-text="item.default_value.name" :item-value="item.default_value.name"
                    :placeholder="item.placeholder" clearable
                    @keydown="preventSpecialCharacters($event)"
                    :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                    :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []" autocomplete="off" :ref="`searchInput${index}`"
                    :id="`_${item.name}`" @keyup="getValuesFromAPI(`searchInput${index}`, item.default_value, index)"
                    @change="checkForValidationOrFunction(item, fields[item.name], index)"
                  >
                  </v-autocomplete>
                </template>
                <template v-else-if="item.default_value.selectType === 'module'">
                  <v-autocomplete outlined :items="item.default_value.options" :label="$t(item.label)" :ref="`autocompleteRef${index}`" :key="autoCompleteRender" dense
                    :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                    :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []" v-model="fields[item.name]" :multiple="item.default_value.is_multiselect"
                    autocomplete="off" :placeholder="item.placeholder" clearable :id="`_${item.name}`"
                    @change="checkForValidationOrFunction(item, fields[item.name], index)" @keydown="preventSpecialCharacters($event)"
                    @click="getFromModuleName(item.default_value.selectedModule, index)"
                    @keyup="getValuesForModule({ event: $event, value: item.default_value, index, ref: `autocompleteRef${index}` })"
                    item-text="name" item-value="_id">
                    <template v-slot:append-item>
                      <div v-intersect="onIntersect" class="pa-4 teal--text" />
                    </template>
                    <template #no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('startTyping') }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                  </v-autocomplete>
                </template>
                <template v-else-if="item.default_value.selectType === 'default'">
                  <v-autocomplete outlined :items="item.default_value.options" :label="$t(item.label)" dense
                    :item-text="item.enableoptiontranslations ? val => $t(`${moduleName}_${item.name}_option_${val.value}`) : val => val.label" item-value="value"
                    :multiple="item.default_value.is_multiselect" v-model="fields[item.name]" :placeholder="item.placeholder" clearable
                    :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                    :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []" :id="`_${item.name}`"
                    @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-autocomplete>
                </template>
                <template v-else-if="item.default_value.selectType === 'custom'">
                  <template v-if="moduleName === 'Account'">
                    <v-combobox outlined :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []" :items="item.customOptions"
                      :item-text="item.optionName" :item-value="item.optionValue"
                      :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                      :multiple="item.default_value.is_multiselect" v-model="fields[item.name]" :placeholder="item.placeholder" clearable
                      :label="$t(item.label)" dense :ref="`customSearch_${index}`" :loading="item.loading" :id="`_${item.name}`"
                      @change="checkForValidationOrFunction(item, fields[item.name], index)"
                    >
                    </v-combobox>
                  </template>
                  <v-autocomplete outlined :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []" v-else
                    :item-text="item.optionName" :item-value="item.optionValue" :items="item.customOptions"
                    :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                    @keydown="preventSpecialCharacters($event)"
                    :multiple="item.default_value.is_multiselect" v-model="fields[item.name]" :placeholder="item.placeholder" clearable
                    :label="$t(item.label)" dense :ref="`customSearch_${index}`" :loading="item.loading" :id="`_${item.name}`"
                  >
                  </v-autocomplete>
                  <!-- <v-autocomplete outlined :items="item.customOptions" :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                    :item-text="item.optionName" :item-value="item.optionValue"
                    :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                    :multiple="item.default_value.is_multiselect" v-model="fields[item.name]" :placeholder="item.placeholder" clearable
                    :label="$t(item.label)"   dense :ref="`customSearch_${index}`" :loading="item.loading" :id="`_${item.name}`"
                    @change="checkForValidationOrFunction(item, fields[item.name], index)">
                  </v-autocomplete> -->
                </template>
              </template>
              <template v-if="item.type === 6">
                <v-menu v-model="item.date_menu" :close-on-content-click="false" max-width="290">
                  <template #activator="{ on }">
                    <v-text-field outlined  v-model="fields[item.name]" :placeholder="item.placeholder" dense clearable
                      prepend-icon="mdi-calendar" :label="$t(item.label.trim())" readonly v-on="on" @click:clear="fields[item.name] = null; checkForValidationOrFunction(item, fields[item.name], index)"
                      :id="`_${item.name}`" :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                      :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="item.date_value" @input="fields[item.name] = parseDate(item.date_value); item.date_menu = false; checkForValidationOrFunction(item, fields[item.name], index)"
                    no-title>
                  </v-date-picker>
                </v-menu>
              </template>
              <template v-if="item.type === 7">
                <v-menu :ref="`time_menu_${index}`" v-model="item.time_menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="fields[item.name]"
                  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template #activator="{ on }">
                    <v-text-field outlined  v-model="fields[item.name]" :placeholder="item.placeholder" clearable dense prepend-icon="mdi-timer" :id="`_${item.name}`"
                      :label="$t(item.label)" readonly v-on="on"
                      :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                      @click:clear="fields[item.name] = null; checkForValidationOrFunction(item, fields[item.name], index)">
                    </v-text-field>
                  </template>
                  <v-time-picker v-if="item.time_menu" v-model="fields[item.name]" format="24hr" full-width
                    @click:minute="$refs[`time_menu_${index}`][0].save(fields[item.name]); checkForValidationOrFunction(item, fields[item.name], index)">
                  </v-time-picker>
                </v-menu>
              </template>
              <template v-if="item.type === 8">
                <v-file-input outlined :label="$t(item.label)" v-model="fields[item.name]"  :placeholder="item.placeholder" dense :id="`_${item.name}`"
                  :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                  :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                  @change="checkForValidationOrFunction(item, fields[item.name], index)">
                </v-file-input>
              </template>
              <template v-if="item.type === 9">
                <v-subheader v-if="item.label" class="pa-0 font-weight-bold"> {{ $t(item.label) }}: </v-subheader>
                <v-divider></v-divider>
              </template>
              <template v-if="item.type === 10">
                <v-subheader v-if="item.label" class="pa-0 font-weight-bold"> {{ $t(item.label) }}: </v-subheader>
                <VueSignaturePad width="100%" height="180px" class="signature--pad" :ref="`signaturePad_${item._id}`" />
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn color="info" fab x-small dark v-on="on" class="mt-1" @click="clearThisSignature(item._id)">
                      <v-icon> mdi-cached </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('reset') }}</span>
                </v-tooltip>
              </template>
              <template v-if="item.type === 11">
                <v-card class="pa-1">
                <v-card-title class="pa-1 caption font-weight-bold">
                  {{ $t(item.label) }}
                  <v-spacer></v-spacer>
                </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pa-1">
                    <module-render :listOfFields="item.panel_fields" ref="panelFormReference" :fields="fields" :isRecordCreation="isRecordCreation"></module-render>
                  </v-card-text>
                </v-card>
              </template>
              <template v-if="item.type === 12">
                <v-text-field outlined  :label="$t(item.label)" :placeholder="item.placeholder" v-model="fields[item.name]"
                  :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : $_emailValidation"
                  dense @change="checkForValidationOrFunction(item, fields[item.name], index)" :id="`_${item.name}`"
                  :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false">
                </v-text-field>
              </template>
              <template v-if="item.type === 13">
                <v-text-field type="number" outlined  :label="$t(item.label)" :placeholder="item.placeholder" :id="`_${item.name}`"
                  :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                  :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                  @change="checkForValidationOrFunction(item, fields[item.name], index)"
                  v-model="fields[item.name]" dense>
                </v-text-field>
              </template>
              <template v-if="item.type === 14">
                <v-text-field outlined type="number"  :label="$t(item.label)" :placeholder="item.placeholder" v-model="fields[item.name]" dense
                  :id="`_${item.name}`" :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                  :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                  @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-text-field>
              </template>
              <template v-if="item.type === 15">
                <v-autocomplete outlined :items="getUsers" :label="$t(item.label)" :multiple="item.default_value.is_multiselect" dense v-model="fields[item.name]"
                  clearable item-text="name" item-value="_id" :placeholder="item.placeholder"
                  :disabled="((item.label === 'Event_create_for') ? ((userDetails.isadmin) ? !!eventId : true) : false) || item.disabled"
                  :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                  :id="`_${item.name}`" :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                  @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-autocomplete>
              </template>
              <template v-if="item.type === 16">
                <v-menu v-model="item.dateTimeMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                  <template  #activator="{ on }">
                    <v-text-field slot="activator" v-model="fields[item.name]" :id="`_${item.name}`" clearable :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                      :label="$t(item.label)" prepend-icon="mdi-timetable" v-on="on" outlined dense hide-details
                      :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                      @click:clear="fields[item.name] = null; item.dateTimePicker = null; item.timePicker = null; checkForValidationOrFunction(item, fields[item.name], index)"
                    ></v-text-field>
                  </template>
                  <v-tabs v-model="item.active" color="primary lighten-1" dark centered slider-color="white">
                    <v-tab ripple>
                      <v-icon color="white" class="pr-2">mdi-calendar</v-icon>
                    </v-tab>
                    <v-tab ripple>
                      <v-icon color="white" class="pr-2">mdi-clock-outline</v-icon>
                    </v-tab>
                    <v-tab-item>
                      <v-date-picker v-model="item.dateTimePicker" color="primary lighten-1" no-title @change="item.active = 1" :id="`_${item.name}`" ></v-date-picker>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-time-picker format="24hr" :close-on-content-click="false" v-model="item.timePicker" color="primary lighten-1"
                          @change="item.dateTimeMenu = false; fields[item.name] = $formatter.formatDateTimeForPicker(item.dateTimePicker, item.timePicker); checkForValidationOrFunction(item, fields[item.name], index)">
                        </v-time-picker>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </v-menu>
              </template>
              <template v-if="item.type === 17"><!-- Just copied code from web, download and delete methods not added yet -->
                <v-file-input outlined :label="$t(item.label)" v-model="fields[item.name]" :placeholder="item.placeholder" dense
                  :id="`_${item.name}`" :ref="`attachment`" multiple>
                </v-file-input>
                <v-sheet class="py-4">
                  <v-chip-group multiple active-class="primary--text">
                    <v-card small v-for="(file, index) in fields[`${item.name} oldAttachments`]" :key="index" width="200" outlined>
                      <v-row class="mt-0 py-0">
                        <v-col lg="2">
                          <v-icon class="mx-1" size="18">mdi-file</v-icon>
                        </v-col>
                        <v-col lg="6" class="text-truncate py-0 d-flex align-center justify-center">
                          <span class="pl-3 caption">{{ file.originalfilename || file.OriginalFileName }}</span><br><br>
                        </v-col>
                        <v-col lg="3" class="px-0">
                          <v-layout>
                            <v-flex>
                              <v-icon size="18" color="success">mdi-download</v-icon><!-- @click="downloadAttachmet(item.name, file)" -->
                            </v-flex>
                            <v-flex>
                              <v-icon size="18" color="error">mdi-close-circle-outline</v-icon><!-- @click="deleteAttachmet(item.name, file)" -->
                            </v-flex>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-chip-group>
                </v-sheet>
              </template>
              <template v-if="item.type === 18">
                <v-text-field outlined  :label="$t(item.label)" :placeholder="item.placeholder" :id="`_${item.name}`"
                  v-model="fields[item.name]" dense disabled>
                </v-text-field>
              </template>
              <template v-if="item.type === 19">
                <html-editor v-model="fields[item.name]" :label="$t(item.label)" :id="`_${item.name}`"></html-editor>
              </template>
            </v-col>
          </template>
        </template>
      </template>
    </v-row>
  </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import customScript from '../../mixins/customScriptHandling'
export default {
  mixins: [customScript],
  props: {
    listOfFields: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Object,
      default: () => {}
    },
    eventId: String,
    moduleName: String,
    propValue: String,
    isRecordCreation: Boolean
  },
  data () {
    return {
      restictConditionScript: false,
      fieldsResricted: [''],
      reRender: 0,
      autoCompleteRender: 0,
      deleteDocumentId: 0,
      deleteDocumentName: '',
      updateDocument: {},
      updateFilePicker: [],
      updateDocumentProp: '',
      showForm: false,
      self: this,
      skip: 0,
      limit: 30,
      getFromModuleValues: {},
      currentModuleName: ''
    }
  },
  components: {
    'module-render': () => import('./ModuleFormRender'),
    'html-editor': () => import('@/components/TextEditor.vue')
  },
  mounted () {
    // console.log('Test')
    this.$root.$on('callRerender', () => {
      this.reRender++
    })
    setTimeout(() => {
      this.listOfFields.forEach((element, index) => {
        let validationScript = ''
        element.isShow = true
        element.loading = false
        element.validationCallback = [true]
        // Simple Conditional
        if (element.fielddisplayconditionwhen) {
          const foundElementIndex = this.listOfFields.findIndex((field) => field.name === element.fielddisplayconditionwhen)
          if (foundElementIndex !== -1) {
            this.listOfFields[foundElementIndex].hideField = element.name
            this.listOfFields[foundElementIndex].hideConditions = {
              condition: element.fielddisplaycondition,
              value: element.fielddisplayconditionvalue
            }
            this.checkForValidationOrFunction(this.listOfFields[foundElementIndex], this.fields[this.listOfFields[foundElementIndex].name], foundElementIndex)
          }
        }
        // Conditional Script manipulation
        try {
          if (element.conditionscript) {
            let conditionScript = this.decodeStringTobase64(element.conditionscript)
            conditionScript = `var show=true; \n${conditionScript} \n return show`
            // eslint-disable-next-line
            element.isShowElement = new Function('data', 'instance', conditionScript)
          }
        } catch {}
        // Validation Script manipulation
        if (element.validationscript) {
          if (element.validateon === 'blur') {
            validationScript = this.decodeStringTobase64(element.validationscript)
            this.addValidationMethod(`${element.name}_validation`, validationScript)
            element.validationCallback = `${element.name}_validation`
          } else {
            element.validationCallback = [true]
          }
        }
        // Logical manipulation
        if (element.logicscripts && element.logicscripts.length > 0) {
          element.logicscripts.forEach(logic => {
            const logicScript = this.decodeStringTobase64(logic.logicscript)
            if (element.type === 5 && logic.logicevent === 'change') {
              element[logic.logicname] = logicScript
            } else {
              element[logic.logicname] = logicScript
              // eslint-disable-next-line
              setTimeout(() => {
                const elementItem = this.$el.querySelector(`#_${element.name}`)
                if (elementItem) {
                  elementItem.addEventListener(logic.logicevent, (event) => {
                    this.eventHandler(event, index, logic)
                  })
                }
              }, 1000)
            }
          })
        }
      })
      // done fixes
      this.showForm = true
    })
  },
  computed: {
    ...mapGetters(['getUsers', 'userDetails'])
  },
  methods: {
    getValidation (validate, type = null) {
      switch (validate) {
        case 'required':
          if (type) return this.$_multiSelectValidation
          else return this.$_requiredValidation
        default:
          return null
      }
    },
    onIntersect (entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.currentModuleName !== this.getFromModuleValues.moduleName) {
          this.currentModuleName = this.getFromModuleValues.moduleName
          this.skip = 0
        } else {
          this.skip += 30
        }
        this.$root.$emit('getModuleOptions', {
          searchText: '',
          moduleName: this.getFromModuleValues.moduleName,
          index: this.getFromModuleValues.index,
          skip: this.skip,
          limit: 30
        })
      }
    },
    getFromModuleName (moduleName, index) {
      this.getFromModuleValues = { moduleName: moduleName, index: index, skip: this.skip }
    },
    // Extension get method
    getProperty (key) {
      if (this.moduleName === 'Account' && key === 'name') return this.fields[key] ? this.fields[key].navn : ''
      else return this.fields[key]
    },
    // Extension set method
    setProperty (key, value) {
      this.fields[key] = value
    },
    canExecuteScript (fieldName) {
      let execute = true
      if (this.restictConditionScript && this.fieldsResricted.includes(fieldName)) execute = false
      return execute
    },
    setRestrictionToScriptExecution (fieldName, value) {
      this.restictConditionScript = value
      if (value) this.fieldsResricted.push(fieldName)
      else this.fieldsResricted = this.fieldsResricted.filter(x => x !== fieldName)
    },
    showAlert (type, message) {
      this.$root.$emit('snackbar', { snackbar: true, color: type, text: message })
    },
    getCurrentUserInfo () {
      return {
        name: this.userDetails.name,
        email: this.userDetails.email,
        isadmin: this.userDetails.isadmin
      }
    },
    getList (field) {
      const result = this.listOfFields.find(x => x.name === field)
      if (result && result.type === 5) {
        let list = []
        if (result.default_value && result.default_value.options && result.default_value.options.length > 0) list = result.default_value.options
        else list = result.customOptions ? result.customOptions : []
        return list
      } else return []
    },
    // Extension set select options method
    setOptionList (key, value, itemValue) {
      const foundElementIndex = this.listOfFields.findIndex(x => x.name === key)
      if (foundElementIndex !== -1) {
        const item = this.$formatter.cloneVariable(this.listOfFields[foundElementIndex])
        item.customOptions = value
        item.optionName = itemValue
        item.optionValue = itemValue
        this.$set(this.listOfFields, foundElementIndex, item)
      }
    },
    parseDate (date) {
      return this.$formatter.formatDate(date, 'YYYY-MM-DD', this.userDetails.dateformat)
    },
    getValuesFromAPI (searchText, value, index) {
      this.$root.$emit('getAPIValues', { searchText, value, index })
    },
    getValuesForModule ({ event, value, index, ref }) {
      this.skip = 0
      if (this.$refs[ref] && this.$refs[ref][0] && this.$refs[ref][0].lazySearch) {
        this.$root.$emit('getModuleOptions', {
          searchText: this.$refs[ref][0].lazySearch,
          moduleName: value.selectedModule,
          index,
          skip: this.skip,
          limit: 30
        })
      }
    },
    addNewFilesHandler (propId, propName, files) {
      this.$root.$emit('addNewDocuments', { property_id: propId, prop_name: propName, files })
      this.fields[propName].attachments = null
    },
    clearThisSignature (index) {
      const self = this
      const reference = `signaturePad_${index}`
      self.$refs[reference][0].clearSignature()
    },
    // Common validation method on blur
    addValidationMethod (name, script) {
      this[name] = () => [
        // eslint-disable-next-line
        new Function('input', 'instance', 'data', script)
      ]
    },
    // Manipulation for Validation on change
    checkForValidationOrFunction (element, value, index) {
      element.conditionscript = () => {}
      // Minumum length validation
      if (element.minlength && value && (value.length < element.minlength)) {
        element.validationCallback = [`Minimum length should be ${element.minlength}`]
        this.$set(this.listOfFields, index, element)
        return false
      }
      // Maximum length validation
      if (element.maxlength && value && (value.length > element.maxlength)) {
        element.validationCallback = [`Maximum length should be ${element.maxlength}`]
        this.$set(this.listOfFields, index, element)
        return false
      }
      // Validate based on regex
      if (element.regexpattern) {
        var regex = new RegExp(element.regexpattern)
        if (regex.test(value)) element.validationCallback = [true]
        else element.validationCallback = ['Not in expected pattern']
        this.$set(this.listOfFields, index, element)
        return false
      }
      // Validate based on script
      if (element.validationscript && element.validateon !== 'blur') {
        let validationScript = this.decodeStringTobase64(element.validationscript)
        validationScript = `var valid=true;\n${validationScript}\nreturn valid`
        // eslint-disable-next-line
        let validationFunction = new Function('input', 'data', 'instance', validationScript)
        const result = validationFunction(value, this.fields, this.self)
        element.validationCallback = [result]
        this.$set(this.listOfFields, index, element)
        return false
      }
      // Conditional hide
      if (element.hideField) {
        const foundElementIndex = this.listOfFields.findIndex(x => x.name === element.hideField)
        // eslint-disable-next-line
        const checkCondition = element.type === 3 ? element.hideConditions.value == value.toString() : element.hideConditions.value === value // Condition for checkbox
        if (checkCondition) {
          this.listOfFields[foundElementIndex].isShow = element.hideConditions.condition
        } else this.listOfFields[foundElementIndex].isShow = !element.hideConditions.condition
      }
      // Onchange select logic script
      // if (element.type === 5 && element.logicscripts && element.logicscripts.length > 0) {
      if (element.logicscripts && element.logicscripts.length) {
        if (element.logicscripts.length) {
          const haschangeEvent = element.logicscripts.find((eventType) => (eventType.logicevent && ['change', 'onchange'].includes(eventType.logicevent)))
          if (haschangeEvent) {
            // eslint-disable-next-line
            const logicalFunction = new Function('instance', 'input', 'data', element[haschangeEvent.logicname])
            logicalFunction(this, value, this.fields)
          }
        }
      }
      this.$set(this.listOfFields, index, element)
    },
    eventHandler (evt, index, logic) {
      const element = this.$formatter.cloneVariable(this.listOfFields[index])
      // eslint-disable-next-line
      let logicalFunction = new Function('instance', 'input', 'data', element[logic.logicname])
      logicalFunction(this, evt.target.value, this.fields)
    }
  },
  beforeDestroy () {
    this.$root.$off('callRerender')
  }
}
</script>
<style>
  .attachment-icon-delete {
    float: right !important;
  }
  /* .formRender {
    height: calc(100vh - 108px) !important;
    overflow-y: auto;
    overflow-x: hidden !important;
  } */
</style>
